body {
  padding: 0;
  margin: 0;

  overflow-x: hidden;

  font-size: 0.875rem;

  /* background: black; */
  /* color: #191c24; */

  font-family: Museo-Sans, sans-serif;
}

/*login page css  */
.main-box {
  max-width: 430px;
  min-height: 420px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-1 {
  display: flex;
  align-items: center;
}

.h-full {
  height: 100vh;
}

.bg {
  background-color: #000;
}

.logo {
  min-height: 40px;
  max-width: 150px;
}

.form-control {
  border: none;
}

.card-box {
  border-radius: 10px;
  box-shadow: -1px -2px #9952ff, 1px 2px 2px 2px #b66dff;
  filter: drop-shadow(20px);
  max-width: 430px;
}

.card-bg {
  background-color: #ffffff;
  border-radius: 10px;
}

.Username {
  border: 1px solid #2a3038;
  width: 80%;
  margin: 25px auto;
  border-radius: 5px;
}

.border-none {
  border: none;
}

.login-btn-box {
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn {
  background-color: #9952ff;
  font-size: 16px;
  color: #ffffff;
  height: 42px;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.btn:hover {
  background: white;
  border: 1px solid #9952ff;
  color: #9952ff;
}

/* login page css end */

.main-container {
  display: flex;
  overflow: hidden;
}

.main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  background-color: black;
  position: relative;
  color: white;
  min-height: 100vh;
  width: 350px;
  transition: all 0.7s;
  position: fixed;
  z-index: 99999;
}

.top_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -3px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.bars {
  color: white;
  font-size: 25px;
  display: flex;
  align-items: center;
  transition: all 0.7s;
}

.link {
  display: flex;
  color: #ffffff;
  padding: 20px 30px;
  font-size: 18px;
  gap: 20px;
  transition: all 0.5s;
  box-sizing: border-box;
}

.link:hover {
  background-color: #9952ff;
  color: #ffffff;
  transition: all 0.5s;
  text-decoration: none;
}

.active {
  background-color: #9952ff;
}

.icon,
.link_text {
  font-size: 18px;
}

/*** Section ***/

.section-head {
  box-sizing: border-box;
  min-height: 100vh;
  padding-left: 77px;
  width: 100%;
}

.section-container {
  width: 100%;
  background-color: black;
  color: #000;
}

.content-body {
  background: #fff;
  border-radius: 20px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: 99%;
  min-height: 90vh;
}

.header {
  align-items: center;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: flex-end;
}

.essential {
  font-size: 20px;
  font-weight: 500;
  transition: all 0.5s;
  padding-left: 30px;
}

.hrb {
  border-bottom: 0.5px solid #f0eaea;
}

.copyright {
  font-size: 12px;
  position: absolute;
  bottom: 30px;
  left: 20px;
  text-align: center;
}

/**** main-section ***/
.main-section {
  max-width: 100%;
  padding: 12px 50px;
  /* background-color: #fff; */
}

.main-section-dashboard {
  max-width: 100%;
  padding: 12px 50px;
}

.main-section-dashboard .heading {
  font-size: 32px;
  font-weight: 500;
  border-bottom: 3px solid #9952ff;
  border-radius: 3px;
  max-width: fit-content;
}

.main-section .heading {
  font-size: 32px;
  font-weight: 500;
  border-bottom: 3px solid #9952ff;
  border-radius: 3px;
  max-width: fit-content;
}

.imgContainer {
  height: 100px;
  width: 100px;
}

.imgContainer img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.table-content {
  max-height: 650px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  margin: 45px 0;
  padding: 12px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.badge {
  padding: 6px 15px;
}

td {
  vertical-align: middle !important;
}

.copyright {
  font-size: 12px;
  position: absolute;
  bottom: 30px;
  /* padding-left: 23px; */
}

/* dashboard start*/

.main-outer-box {
  max-width: 290px;
  min-height: 280px;
  margin: 0px auto;
  border-top: 0.5px solid #9952ff;
  border-left: 0.5px solid #9952ff;
  border-radius: 10px;
  box-shadow: 1px 2px 1px 2px rgba(158, 89, 248, 0.25);
  color: #323a45;
}

.text-box {
  border-bottom: 1px solid #8a959e;
  width: 80%;
  margin: 0px auto;
}

.ring {
  max-width: 150px;
  min-height: 150px;
  border: 17px solid #007adb;
  border-radius: 75px;
  margin: 17px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ring.blue {
  border: 17px solid #007adb;
}

.ring.purple {
  border: 17px solid #9952ff;
}

.ring.orange {
  border: 17px solid #ff9844;
}

.ring.red {
  border: 17px solid #ef2f46;
}

.ring.green {
  border: 17px solid #00db3d;
}

/* dashboard end */

.table th {
  border-top: none !important;
}

.custom-select {
  max-width: max-content;
}

.filter-btn {
  height: auto;
  max-width: max-content !important;
  padding: 0.15rem 1rem;
  margin-left: 17px;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 16px;
}

.manage-btn {
  height: auto;
  max-width: max-content !important;
  padding: 0.18rem 0.8rem;
  margin-left: 17px;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
}


.btn-link:hover {
  text-decoration: none !important;
}

/* nopage css */

.box {
  color: #f7f6f8;
  background-color: #007adb;
}

.inner-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nopage-text-404 {
  font-size: 160px;
  font-weight: 500;
  text-align: center;
}

.nopage-text {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
}

.nopage-text-right {
  font-size: 30px;
  text-align: center;
}

/* nopage end */

/* plan page */
.btn-box {
  border-bottom: 1px solid #9952ff;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 5px;

}

.plan-btn {
  height: 40px;
  width: 180px;
  font-size: 18px;
  background-color: #9952ff;
  color: #ffffff;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

}

.plan-btn:hover {
  background-color: #ffffff;
  color: #9952ff;
  border: 3px solid #9952ff;
}

button:focus {
  outline: none;
}

.plan-text-heading {
  font-size: 42px;
  text-align: center;
  color: #a9a6a6;
  margin-top: 5rem !important;
}

.plan-text {
  font-size: 20px;
  text-align: center;
  color: #a9a6a6;
}

/* 
.edit-plan-container{
  min-height: 300px;
  width: 80%;
  border: 1px solid #9952ff;
  margin: 50px auto;
  border-radius: 10px;
}
.plan-name-container{
  border-bottom: 1px solid #9952ff;
  width: 94%;
  margin: 0px auto;
}
.plan-name-box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 100%;
  height: 50px;
}
label{
  margin-bottom: 0rem;
}
.Plan-name-label{
font-size: 22px;
padding: 5px 25px;
box-sizing: border-box;
}
@media screen and (max-width:600px){
  .Plan-name-label{
    font-size: 18px;
    padding: 5px 20px;
    }
 }
.input-plan{
  border:1px solid #9952ff;
  height: 50px;
  width: 50%;
  border-radius: 10px;
  font-size: 18px;
  padding-left: 20px;
  box-sizing: border-box;
}
@media screen and (max-width:600px){
 .input-plan{
  font-size:14px;
 } 
}
.check-box{
  height: 16px;
  width: 16px;
  margin-top: 1px;
}
.paln-list{
  display: flex;
} */

.plan-content {
  padding: 40px 240px;
}

@media screen and (max-width: 1260px) {
  .plan-content {
    padding: 10px 40px;
  }
}

@media screen and (max-width: 860px) {
  .plan-content {
    padding: 10px;
  }

  .permission-list {
    margin: 10px !important;
  }
}

.plan-container {
  border: 1px solid #9952ff;
  border-radius: 5px;
}

.label_text_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 60px;
  padding: 0 36px;
  border-bottom: 1px solid #9952ff;
}

.label_text {
  display: flex;
  align-items: baseline;
  padding: 12px;
}

.label_text label {
  font-size: 18px;
  margin-right: 10px;
}

.label_text input,
.label_text select {
  border: 1px solid #9952ff;
  border-radius: 10px;
  font-size: 18px;
  padding: 12px 14px;
  box-sizing: border-box;
}

.permission-list {
  margin: 10px 60px;
}

.permission-list ul {
  padding: 0 10px;
}

.permission-list ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 45px;
}

/* .permission-list ul li span::before {
  content: "•";
  color: #232323;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
} */

.permission-list ul li input,
.permission-list ul li select {
  text-align: center;
  border: 1px solid #9952ff;
  width: 150px;
  padding: 4px;
}

.price {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 6px 55px;
  flex-wrap: wrap;
}

.price input {
  border: 1px solid #9952ff;
  border-radius: 0px;
  font-size: 18px;
  padding: 12px 14px;
  box-sizing: border-box;
}

.price label {
  font-size: 18px;
  font-weight: 500;
  /* padding-left: 27px; */
}

.input-group {
  width: 153px;
}

.input-group-text {
  border: 1px solid #9952ff;
  border-radius: none !important;
}

.save-plan {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px;
}

/* card */

.card-box-slider {
  min-height: 700px;
  border-radius: 10px;
  border: 1px solid #9952ff;
  max-width: 88%;
  margin: 0px auto;
  position: relative;
}

@media screen and (max-width: 769px) {
  .card-box-slider {
    height: 800px;
  }
}

@media screen and (max-width: 913px) {
  .card-box-slider {
    height: 820px;
  }
}

@media screen and (max-width: 1024px) {
  .card-box-slider {
    height: 800px;
  }
}

@media screen and (max-width: 1284px) {
  .card-box-slider {
    height: 637px;
  }
}

.card-heading-text {
  font-weight: 700;
  color: #323a45;
  padding-left: 45px;
}

.card-list {
  color: #323a45;
  font-size: 16px;
}

.card-flex {
  display: flex;
  justify-content: center;
  /* margin: 0px auto; */
  position: absolute;
  bottom: 0;
  /* left: 34px; */
  background: #9952ff;
  width: 100%;
  padding: 18px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

@media screen and (max-width: 460px) {
  .card-flex {
    left: 24px;
  }
}

/* .Price-tag{
font-size: 25px;
color:#323a45;
font-weight: 700;
} */
.amount-box {
  display: flex;
}

.free {
  font-size: 28px;
  color: #fff;
  font-weight: 700;
}

.symbol {
  color: #fff;
  font-size: 16px;
  padding-right: 2px;
  font-weight: 700;
}

.period {
  color: #ffffff;
  font-size: 14px;
  padding-left: 2px;
  padding-top: 14px;
}

.card-btn-box {

  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  align-items: baseline;

}

.card-btn {
  background-color: #ffffff;
  color: #9952ff;
  border: 1px solid #9952ff;
  height: 45px;
  width: 180px;
  font-size: 18px;
  box-sizing: border-box;
  margin-bottom: 20px;

  transition: all 0.3s ease;

  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


}


.card-btn:hover {
  background-color: #9952ff;
  color: #ffffff;
}

.status {
  position: absolute;
  top: 9px;
  height: 40px;
  width: 100px;
  font-size: 16px;
  background-color: rgb(16, 128, 6);
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 2px;
  right: 50px;
  font-weight: 700;
}

.status-inactive {
  position: absolute;
  top: 9px;
  height: 40px;
  width: 100px;
  font-size: 16px;
  background-color: rgb(212, 147, 6);
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 2px;
  right: 50px;
  font-weight: 700;
}

.after-slider {
  position: relative;
}

.slick-next {
  right: -20px !important;
}

.slick-prev {
  left: -30px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #9952ff !important;
  font-size: 32px !important;
}

.popup-box {
  max-height: 300px;
  max-width: 520px;
  background-color: #ffffff;
}

@media screen and (max-width: 660px) {
  .popup-box {
    max-width: 420px;
  }
}

.popup-box-text {
  color: #323a45;
  font-weight: 700;
}

@media screen and (max-width: 660px) {
  .popup-box-text {
    font-size: 22px;
  }
}

.modal-flex {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.modal-btn {
  background-color: #9952ff;
  color: #ffffff;
  border: transparent;
  height: 45px;
  width: 180px;
  font-size: 18px;
  box-sizing: border-box;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

}

.modal-btn:hover {
  border: 1px solid #9952ff;
  background-color: #ffffff;
  color: #9952ff;
  transition: all 0.5s;
}

@media screen and (max-width: 600px) {
  .modal-btn {
    height: 40px;
    width: 160px;
  }
}

/* card end */

/* plan page end */

/******** Loader styling starts here **************/

.loader-container {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader-body {
  position: relative;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  background: #383d41;
  border-radius: 7px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  padding: 18px 60px;
}

.loader {
  justify-content: center;
}

.back-btn {
  font-size: 34px;
  color: #9952ff;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.back-btn span {
  font-size: 18px;
  color: #232323;
  margin-left: 5px;
}

.no-csr {
  cursor: not-allowed;
}

.search-input {
  border: 1px solid #9952ff;
  padding: 6px 12px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #9952ff !important;
  border-color: #9952ff !important;
}

.page-link {
  color: rgba(0, 0, 0, 0.9) !important;
}

.no-text {
  font-size: 20px;
  margin: 21px 130%;
  width: 177px;
}

.no-text-2 {
  margin: 21px 160%;
  width: 177px;
}

.label-input {
  width: 330px !important;
  text-align: left !important;
  padding: 5px 12px;
  border: 1px solid #6c757d33 !important;
}

.edit-tick {
  font-size: 24px;
  color: green;
  cursor: pointer;
  margin-left: 8px;
}

@media screen and (max-width: 800px) {
  .no-text {
    font-size: 20px;
    margin: 20px 120%;
    width: 177px;
  }

  .no-text-2 {
    margin: 20px 140%;
    width: 177px;
  }
}

@media screen and (max-width: 650px) {
  .no-text {
    font-size: 20px;
    margin: 20px 60%;
    width: 177px;
  }

  .no-text-2 {
    margin: 20px 80%;
    width: 177px;
  }
}

@media screen and (max-width: 500px) {
  .no-text {
    font-size: 20px;
    margin: 20px 40%;
    width: 177px;
  }

  .no-text-2 {
    margin: 20px 60%;
    width: 177px;
  }
}

.manage-border {
  border-bottom: 1px solid #a9a6a6;
}

.outer-pause-container {
  display: flex;
}

.custom-date {
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
  min-width: 250px;
}

.font-bold {
  font-weight: 700;
}

.react-date-picker__wrapper {
  border-radius: 6px;
  border: 1px solid rgb(184, 175, 175) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 120px;
}

.react-date-picker__button svg {
  height: 12px;

}

.react-date-picker__clear-button {
  display: none;
}


.react-date-picker__button {
  padding: 0px !important;
}

.react-date-picker__inputGroup {
  padding: 0px !important;
  min-width: 0% !important;
}

.padding-box {
  padding-left: 50px;
}

.px-d {
  padding: 0 21px;
  min-width: 80%;
  color: #56749b;
  font-weight: 500;
}

.manage-flex {
  display: flex;
  justify-content: flex-end;
}

.manage-box-btn {
  width: 80px;
  height: 32px;
  border-radius: 5px;
  background-color: #9952ff;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: transparent;
}

.manage-popup-box {
  border-radius: 10px;
}

.react-responsive-modal-modal {
  border-radius: 10px;
}

.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
  animation-fill-mode: forwards !important;
}

.info-icon {
  font-size: 17px;
  color: grey;
  cursor: pointer;
}

.plan-list-ul {
  list-style: none !important;
  padding-inline-start: 0px !important;
}

.plan-list-ul .check-in-circle {
  font-size: 15px;
  color: #9952ff;
  margin: 0 6px;
}

.plan-list-ul li span {
  font-size: 14px;
}

.plan-list-ul .plan-value,
.plan-value-array {
  /* font-weight: bold; */
  margin-left: 8px;
}

.info-box.show {
  z-index: 99999;
  position: absolute;
  max-width: 100px;
  background-color: bisque;
  text-align: center;
  box-shadow: 0px 1px 0px #996d22;
  border-radius: 5px;
  right: 40px;
  padding: 2px 10px;
  display: inline;
}

.info-box {
  display: none;
}

.pointer svg {
  cursor: pointer;
}

.heading-bottom-line {
  border: 2px solid #9952ff;
  display: block;
  width: 20%;
  margin: 2px 48px;
}

.manage-header {
  font-size: 22px;
  margin-right: 65px;
  font-weight: 500;
}

.plan-name-span {
  font-size: 12px;
  font-weight: 600;
}


.multiSelect-div {
  height: 25px;
  border: 1px solid #9952ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  position: relative;
}

.multiSelect-options {
  position: absolute;
  top: 30px;
  left: 0;
  min-width: 200px;
  background: #fff;
  max-height: 100px;
  color: #000;
  padding: 10px;
  z-index: 99999;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.selected-values {
  overflow: hidden;
  color: #6c757d;
}

.info-icon-size {
  font-size: 20px;
}

.delete-icon,
.archive-icon {
  font-size: 30px;
  border: none !important;
  margin: 0 3px;
}

.delete-icon {
  color: #b30022;
}

.archive-icon {
  color: #495057
}

button:disabled {
  cursor: not-allowed;
}

.note-div {
  text-align: center;
  margin-bottom: 12px;
}

.note-text {
  font-size: 14px;
  font-weight: 600;
}

.archived-badge{
  font-size: 10px !important;
  vertical-align: super;
  padding: 6px !important;
}

.edit-label {
  width: 330px !important;
  text-align: left !important;
  padding: 5px 12px;
  border: 1px solid #6c757d33 !important;
}


.checkbox-input{
  height: 20px;
}